.page--members,
.page--about-petzi,
.page--points-of-sale {
  .content {
    p, ul {
      font-weight: 300;
      text-transform: none;
    }
  }
}

.page--campaign, .page--news {
  .content {
    max-width: 42rem;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    display: inline-block;
    max-width: 100%;
  }
}
