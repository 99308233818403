.tabs-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  .is-active {
    color: $white;
    background-color: $brand-color;
  &.dashboard__tab {
    background-color: $alt-brand-color;
    color: black;
  }
  }
}

.tab {
  display: none;
}

.tab.is-active {
  display: block;
}

.tabs.no-js .tab {
  border-top: 1px solid $alt-color;
  display: block;
  margin-bottom: 1.5rem;
}

.tabs.no-js .tab:last-child {
  margin-bottom: 0;
}
