.tile {
  overflow: hidden;
  padding: $spacing-unit-default;
  display: inline-block;
  position: relative;

  word-break: break-word;
  text-decoration: none;

  &:hover .tile__hover {
    display: block;
  }

  &:hover::after {
    content: '';

    display: block;
    height: 100%;
    width: 100%;

    z-index: 1;

    position: absolute;
    top: 0;
    left: 0;

    background-color: $brand-color;
    opacity: .9;
  }
}

.tile__date,
.tile__hover,
.tile__title,
.tile__place {
  margin: 0;

  line-height: 1.3;
  text-transform: uppercase;
}

.tile__hover {
  display: none;

  color: $white;

  position: absolute;
  top: $spacing-unit-default;
  left: $spacing-unit-default;

  z-index: 2;
}

/**
 * Tiles responsive font sizes
 */

.tile__date,
.tile__place {
  font-size: 6vw;
}
.tile__title {
  font-size: 8vw;
}
.tile__hover {
  font-size: 10vw;
}

@media (min-width: $screen-xs-masonry) {
  .tile__date,
  .tile__place {
    font-size: 2.125vw;
  }
  .tile__title {
    font-size: 2.883vw;
  }
  .tile__hover {
    font-size: 5vw;
  }
}

@media (min-width: $screen-sm-masonry) {
  .tile__date,
  .tile__place {
    font-size: 1.6vw;
  }
  .tile__title {
    font-size: 2.125vw;
  }
  .tile__hover {
    font-size: 3.75vw;
  }
}

@media (min-width: $screen-md-masonry) {
  .tile__date,
  .tile__place {
    font-size: 1.275vw;
  }
  .tile__title {
    font-size: 1.7vw;
  }
  .tile__hover {
    font-size: 3vw;
  }
}

@media (min-width: $screen-lg-masonry) {
  .tile__date,
  .tile__place {
    font-size: 1.063vw;
  }
  .tile__title {
    font-size: 1.417vw;
  }
  .tile__hover {
    font-size: 2.5vw;
  }
}

// Specific font size for modifier height & width rectangular tile
.masonry-grid__item--width,
.masonry-grid__item--height {
  @media (min-width: $screen-xs-masonry) {
    .tile__date,
    .tile__place {
      font-size: 2.5vw;
    }
    .tile__title {
      font-size: 3.333vw;
    }
  }

  @media (min-width: $screen-sm-masonry) {
    .tile__date,
    .tile__place {
      font-size: 1.875vw;
    }
    .tile__title {
      font-size: 2.5vw;
    }
  }

  @media (min-width: $screen-md-masonry) {
    .tile__date,
    .tile__place {
      font-size: 1.5vw;
    }
    .tile__title {
      font-size: 2vw;
    }
  }

  @media (min-width: $screen-lg-masonry) {
    .tile__date,
    .tile__place {
      font-size: 1.25vw;
    }
    .tile__title {
      font-size: 1.667vw;
    }
  }
}

/**
 * Tiles responsive - corner-banner
 */

.tile__corner-banner {
  z-index: 1;
  position:absolute;
  top: 0;
  right: 0;
  height: 50%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
}

@media (min-width: $screen-xs-masonry) {
  .tile__corner-banner--height {
    height: 25%;
  }
}

/**
 * Tiles modifier styles
 */

.tile--striped {
  color: $black;
  border: 6px solid $brand-color;

  background: repeating-linear-gradient(
                  -45deg,
                  $alt-color-lighter,
                  $alt-color-lighter 2px,
                  $white 2px,
                  $white 10px
  );
}

.tile--white {
  color: $black;

  background-color: $white;
}

.tile--black {
  color: $alt-brand-color;

  background-color: $black;
}

.tile--gray {
  color: $white;
  background-color: $alt-color-light;
  border: 6px solid $alt-color;
  a {
    color: $black;
    &:hover {
      color: $alt-color-lighter;
    }
  }
  input, select {
    color: $black;
    &.btn {
      color: $white;
    }
  }
}

.tile--gray--copy-links {
  label {
    color: $black;
    &:hover {
      color: $alt-color-lighter;
    }
  }
  input[type=text] {
    &:hover {
      cursor: pointer;
    }
  }
}

.tile--light_gray {
  .tile--light_gray__header {
    background-color: $alt-color-light;
  }
  color: $black;
  background-color: $alt-color-lighter;
  a {
    color: $brand-color;
  }
  input, select {
    color: $black;
    &.btn {
      color: $white;
    }
  }
}

.tile--image {
  color: $white;

  background-size: cover;
  background-position: center center;

  .tile__date,
  .tile__title,
  .tile__place {
    text-shadow: 1px 1px 8px $black;

    z-index: 1;
    position: relative;

  }
  &.tile__noimage {
    color: $black;

    .tile__date,
    .tile__title,
    .tile__place {
      text-shadow: none;
    }
  }
}

.tile--blue {
  background: $brand-color;
  border: 6px solid lighten($brand-color, 10%);
}

.tile--white_blue {
  color: $black;
  border: 6px solid $brand-color;
}

.tile--shadow {
  box-shadow: 0 10px 10px -4px $alt-color;
}

.tile--content__center {
  margin-left: -12px;
}
