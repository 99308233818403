/*!
 * https://github.com/jonsuh/hamburgers#customization
 */

$hamburger-padding-x                       : 15px;
$hamburger-padding-y                       : 15px;
$hamburger-layer-width                     : 33px;
$hamburger-layer-height                    : 2px;
$hamburger-layer-spacing                   : 8px;
$hamburger-layer-color                     : #000;
$hamburger-layer-border-radius             : 4px;
$hamburger-hover-opacity                   : 0.7;
$hamburger-hover-transition-duration       : 0.15s;
$hamburger-hover-transition-timing-function: linear;
