.radio-container:hover .checkmark {
  @apply tw-bg-alt-color-lighter;
}

input[type="radio"]:checked + .checkmark {
  @apply tw-bg-white;
}

.checkmark::after {
  @apply tw-absolute tw-top-[2px] tw-left-[2px] tw-h-[14px] tw-w-[14px] tw-rounded-full tw-bg-brand tw-hidden;
  content: '';
}

input[type="radio"]:checked + .checkmark::after {
  @apply tw-block;
}
