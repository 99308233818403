.alert-container {
  @apply tw-fixed tw-top-[80px] lg:tw-top-[85px] tw-left-0 tw-z-10 tw-w-full;
}

.alert {
  padding: 14px;

  .alert--close {
    clear: both;
    float: right;
    font-family: sans-serif;
    cursor: pointer;
    font-size: 1.6em;
    line-height: 1.3rem;
  }
}

.alert--debug {
  color: $white;
  background-color: $black;
}

.alert--info {
  color: $info-text;
  background-color: $info;
  border-bottom: 1px solid $info-text;
}

.alert--success {
  color: $success-text;
  background-color: $success;
  border-bottom: 1px solid $success-text;
}

.alert--warning {
  color: $warning-text;
  background-color: $warning;
  border-bottom: 1px solid $warning-text;
}

.alert--warning--collect-attendee-contacts {
  color: $black;
  background-color: $alt-brand-color-yellow;
  border: none;
  width: 100%;
  max-width: 1000px;
}

.alert--error {
  color: $error-text;
  background-color: $error;
  border-bottom: 1px solid $error-text;
}

// Error class output by DjangoCms in login form
.errorlist {
  margin: 0;
  padding-left: 0;
  color: #F00;
  list-style: none;
  font-size: $font-size-small;
}

@media (max-width: $screen-md-min) {
  .alert-container {
    top: 80px;
    margin: 0 auto;
  }
  .master--members {
    .alert-container {
      top: 0px;
    }
  }
}

@media (min-width: $screen-md-min) {
  .master--members {
    .alert-container {
      top: 80px;
      left: 0;
    }
  }
}
