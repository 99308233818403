form {
  label {
    margin-top: 14px;
    padding-bottom: 0;
  }

  input[type="checkbox"] + label {
    display: inline;
  }

  .helptext {
    color: $alt-color-dark;
    font-size: 80%;
  }
}

form.with-required-indicator {
  label.required::after {
    display: inline-block;
    content: " *";
    color: #ff6767;
  }
}

.form-register {
  max-width: 500px;
}

.form-field-error {
  color: red;
}

.self_tickets_form {
  input[type="number"] {
    @media (max-width: $screen-sm-min) {
      width: 70%;
    }
    width: 50%;
    vertical-align: top;
  }
}

select {
  text-transform: uppercase;
}

@media (min-width: $screen-md-min) {
  .form-update {
    padding-right: 24px;
  }
  .account-form {
    margin-top: 25vh;
  }
}

ul.checkbox-groups > li:not(:first-child) {
  margin-top: 24px;
}


label { cursor: default; }
