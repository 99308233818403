.container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: $spacing-unit-small;
  padding-right: $spacing-unit-small;

  &--small {
    max-width: 800px;
  }

  &--large {
    max-width: 1800px;
  }
}
