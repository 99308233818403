.event-picture img {
    width: 85%;
}

.empty-basket {
    width: 25%;
    margin: 0 auto;
}

@media (max-width: $screen-md-min) {
    .event-picture img {
        width: 99%;
    }
}