/**
 * NEWS FLAG
 */

.news-flag {
  width: 0px;
  height: 200px;

  position: fixed;
  top: 140px;
  right: 0;
  overflow: hidden;
  z-index: 2;

  color: $white;
  background-color: $corail;
  transition: width 0.1s ease-out;

  @media (min-width: $screen-md-min) {
    top: 105px;
  }

  &.active {
    width: 320px;

    .news-flag__body {
      opacity: 1;
    }
  }
}

.news-flag--show {
  width: 60px;
}

.news-flag--hidden {
  width: 0px;
}

  .news-flag__header {
    width: 60px;
    height: 100%;
    padding: $spacing-unit-default 0;
    margin-right: 20px;
    border-right: 1px solid $white;

    cursor: pointer;

    &:hover {
      background-color: lighten($corail, 5%);
    }

    .news-flag__news {
      width: 100px;
      position: relative;
      top: 57px;
      right: 20px;
      transform: rotate(-90deg);
      font-size: 1.7em;

      &::before,
      &::after {
        content: ' * ';
      }
    }
  }

  .news-flag__body {
    height: 100%;
    opacity: 0;

    transition: opacity 0.3s ease-out;

    .news-flag__title {
      font-size: 1.2em;
    }

    .news-flag__date,
    .news-flag__content {
      font-size: $font-size-default;
      text-transform: initial;
    }

    .news-flag__link {
      width: 100%;
      display: inline-block;
      border-bottom: 1px solid $white;

      color: $white;
    }

    .news-flag__info {
      font-size: $font-size-small;
    }
  }
