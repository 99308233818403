.ticket-grid-input-qty {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  padding: 4px !important;
  text-align: center;
}

.ticket-grid-input-donation-amount {
  width: 800px;
  min-width: 80px;
  max-width: 80px;
  padding: 4px !important;
  text-align: center;
}

.sales_ticket_info {
  color: red;
}

.basket-line-warning-quantity {
  color: red;
  font-size: $font-size-small;
  font-style: italic;
}

