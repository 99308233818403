.article-filters {
  ul {
    @media (min-width: $screen-md-min) {
      list-style: none;
      display: inline;
      padding-left: 0;
    }

    li:first-child {
      @media (min-width: $screen-md-min) {
        padding-left: 0;
      }
    }

    li {
      @media (min-width: $screen-md-min) {
        display: inline-block;
        padding: 0 12px;
        vertical-align: middle;
      }
    }
  }

  &__link {
    color: $black;
  }

  &__link--active {
    text-decoration: underline;
    text-decoration-color: $brand-color;
  }

  &--mobile {
    @media (max-width: $screen-sm-max) {
      background-color: $white;
      width: 125px;
      position: absolute;
      top: 0;
      z-index: 999;
      opacity: 0;
      transition: all 0.5s;
      visibility: hidden;
      transform: translateY(0rem);

      ul {
        list-style: none;
        padding: 15px 0 15px 5px;
        margin: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 15px;

        li {
          .article-filters__link--active {
            color: $brand-color;
            text-decoration: none;
          }
        }
      }

      &.animate {
        visibility: visible;
        opacity: 1;
        transform: translateY(2rem);
      }
    }
  }
}
