.grid-dashboard {
  visibility: hidden;
  width: 96%;
  margin: 0 auto;
}

.grid-dashboard__item {
  display: block;
  margin-bottom: 10px;

  #organizer-events-direct-link {
    cursor: pointer;
  }
}

.grid-dashboard__sizer,
.grid-dashboard__item {
  width: 100%; // 1 column
}

@media (min-width: $screen-xs-masonry) {
  .grid-dashboard__sizer,
  .grid-dashboard__item {
    width: calc(50% - 10px); // 2 columns
  }

  .grid-dashboard__item--width {
    width: calc(100% - 10px);
  }
}

@media (min-width: $screen-md-masonry) {
  .grid-dashboard__sizer,
  .grid-dashboard__item {
    width: calc(33.333% - 10px); // 3 columns
  }

  .grid-dashboard__item--width {
    width: calc(66.666% - 10px);
  }
}

@media (min-width: $screen-lg-masonry) {
  .grid-dashboard__sizer,
  .grid-dashboard__item {
    width: calc(25% - 10px); // 4 columns
  }

  .grid-dashboard__item--width {
    width: calc(50% - 10px);
  }
}

.dashboard-event-list {
  li {
    &.active {
      a {
        color: $alt-brand-color;
      }
    }
    a {
      color: $white;
    }
  }
  .accordion__header {
    background-color: transparent;
  }
  .accordion__body {
    background-color: transparent;
  }
}

.stamp {
  position: absolute;
}
