.text-white {
  color: $white;
}

.text-black {
  color: $black;
}

.text-brand {
  color: $brand-color;
}

.text-grey {
  color: $alt-color;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

h1.text-brand {
  overflow-wrap: break-word;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-initial {
  text-transform: initial;
}

.text-underline {
  text-decoration: underline;
}
