.toggle {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  gap: 1ch;

  label {
    margin-top: 0;
  }

  .toggle__input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  .toggle__display {
    --offset: 0.25em;
    --diameter: 1.8em;

    display: inline-flex;
    align-items: center;
    justify-content: space-around;

    width: calc(var(--diameter) * 2 + var(--offset) * 2);
    height: calc(var(--diameter) + var(--offset) * 2);
    box-sizing: content-box;
    position: relative;
    border-radius: 100vw;
    background-color: $tab-color;
    transition: 250ms;
    cursor: pointer;

    &::before {
      content: '';
      width: var(--diameter);
      height: var(--diameter);
      border-radius: 50%;
      box-sizing: border-box;
      position: absolute;
      z-index: 2;
      top: 50%;
      left: var(--offset);
      transform: translate(0, -50%);
      background-color: #fff;
      transition: inherit;
    }

    .toggle__icon {
      &.toggle__icon--cross {
        color: darken($tab-color, 30%);
      }
      &.toggle__icon--cross:hover {
        color: white;
      }

      &.toggle__icon--checkmark {
        color: white;
      }
    }
  }

  .toggle__input:focus + .toggle__display {
    outline: 1px solid #212121;
  }

  .toggle__input:focus:not(:focus-visible) + .toggle__display {
    outline: 0;
  }

  .toggle__input:checked + .toggle__display {
    background-color: $brand-color;
  }

  .toggle__input:checked + .toggle__display::before {
    transform: translate(100%, -50%);
  }

  .toggle__input:disabled + .toggle__display {
    opacity: 0.6;
    filter: grayscale(40%);
    cursor: not-allowed;
  }
}

.toggle-password {
  display: flex;
  justify-content: right;
  button {
    background: none;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
  }
}
