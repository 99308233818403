.attendee-title {
  background-color: $brand-color;
  color: $white;
  margin: 0;
  padding: $spacing-unit-small $spacing-unit-default;
  position: relative;
}

.page--edit-attendee {
  .form-content {
    margin: auto;
    max-width: 700px;
  }
}

.attendee-form-field {
  clear: both;
  margin-bottom: 14px;

  .helptext {
    display: block;
    font-size: small;
    float: right;
    text-align: right;
  }

  input {
    margin: 0px;
  }

  .verification-code-widget {
    width: 100%;
    div {
      width: auto;
      overflow: hidden;
    }
    button {
      display: block;
      width: auto;
      float: left;
      overflow: hidden;
      border-width: 1px;
      border-color: $black;
    }
  }
}
