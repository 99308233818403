$page-margin: $spacing-unit-default;
$page-margin-desktop: $spacing-unit-default * 2;

[x-cloak] {
  display: none !important;
}

.master {
  &:not(.page--agenda) {
    @apply tw-min-h-screen;
    .content {
      @apply tw-px-4 md:tw-px-2 lg:tw-px-8 tw-py-10;
      flex: 1 0 auto;
    }
  }

  &.page--agenda {
    @apply tw-h-auto tw-min-h-screen;
  }

  &.page--login, &.page--protected, &.page--registration-pending {
    .content {
      @apply tw-flex tw-flex-col tw-justify-center tw-relative;
    }
  }
}

hr {
  @apply tw-border tw-border-0 tw-border-t-1 tw-border-solid
}

.master--members {
  @apply tw-mt-12 md:tw-mt-24;
}

.master--members .content {
  min-height: 70vh;
}

.auto-uppercase--disabled {
  text-transform: none;
}

.simple-field {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.width-auto {
  width: auto;
}

.chart {
  max-width: 100%;
}

.sold-chart > div {
  flex-grow: 1;
}

@media only screen and (max-width: 1024px) {
  .column {
    width: 100% !important;
  }
}
