.table-striped thead, .table-header {
  background-color: $alt-color-light;
  color: $white;
}

.table-striped {
  thead tr {
    color: $white;
    background-color: $alt-color-light;
  }

  tbody tr:nth-child(odd) {
    background-color: $alt-color-lighter;
  }

  td, th {
    max-width: 250px;
    padding: $spacing-unit-small;
  }
}

.striped:nth-child(odd) {
  background-color: $alt-color-lighter;
}

.basket-table {
  .basket-table--header {
    background-color: $brand-color;
    color: $white;
  }
  .basket-table--body {
    .grid__item {
      vertical-align: inherit;
    }
  }
}

@media (min-width: $screen-md-min) {
  .customer-table {
    padding-right: 24px;
  }
}
