/**
 * COLLAPSE
 */

.collapse:not(.active) {
  display: none;
}

.collapse--slide {
  overflow: hidden;

  &:not(.active) {
    display: block;
    max-height: 0;
  }
}