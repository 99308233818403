.article-card {
  color: $black;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  &--draft {
    .article-card__img {
      outline: 4px solid $corail;
    }
  }

  &__body {
    flex-grow: 1;
    &__img {
      background-color: #EEEEEE;
    }
  }
}
