.icon {
  display: inline-block;
  height: 40px;
  width: 40px;

  background-position: center;
  background-repeat: no-repeat;
}

.icon--sm {
  height: 24px;
  width: 24px;
}

.icon--tiny {
  height: 16px;
  width: 16px;
}

.icon-cross {
  background-size: contain;
  background-image: url('/static/images/cross-icon.svg');
}

.icon-search {
  background-size: contain;
  background-image: url('/static/images/search.svg');
}

.icon-search--on {
  background-image: url('/static/images/search-on.svg');
}

.icon-cart {
  background-size: contain;
  background-image: url('/static/images/cart.svg');
}

.icon-cart--white {
  background-size: contain;
  background-image: url('/static/images/cart-white.svg');
  width: 32px;
  height: 32px;
}

.icon-cart--on {
  background-image: url('/static/images/cart-on.svg');
}

.icon-calendar--plus {
  background-image: url('/static/images/calendar-plus.svg');
  width: 32px;
  height: 32px;
}

.icon-arrow--down {
  background-size: contain;
  background-image: url('/static/images/arrow-down.svg');
}

.icon-arrow--up {
  background-size: contain;
  background-image: url('/static/images/arrow-up.svg');
}

.icon-close {
  position: relative;

  &:before, &:after {
    content: '';
    display: block;
    background-color: #000;
    width: 100%;
    height: 100%;
    position: absolute;
    transform-origin: center center;
  }

  &:before {
    transform: rotateZ(-45deg) scaleY(.07) scaleX(1.414213562);
  }

  &:after {
    transform: rotateZ(45deg) scaleY(.07) scaleX(1.414213562);
  }
}

.icon-ticket {
  background-size: contain;
  background-image: url('/static/images/ticket.svg');
}

.icon-ticket--blue {
  background-size: contain;
  background-image: url('/static/images/ticket--blue.svg');
}

.icon-eye {
  background-size: contain;
  background-image: url('/static/images/eye.svg');
}

.icon-login {
  background-size: contain;
  background-image: url('/static/images/login.svg');
}

.icon-external-link {
  background-size: contain;
  background-image: url('/static/images/external-link.svg');
  vertical-align: top;
  color: transparent;
  font-size: 0;
}

.icon-login--on {
  background-image: url('/static/images/login-on.svg');
}

.icon-facebook {
  background-image: url('/static/images/facebook.png');
  background-size: 12.5px;
}

.icon-instagram {
  background-image: url('/static/images/instagram.png');
  background-size: 25px;
}

.icon-twitter {
  background-image: url('/static/images/twitter.png');
  background-size: 25px;
}

.icon-admin {
  display: inline-block;
  width: 23px;
  vertical-align: middle;

  &--small {
    width: 18px;
  }
}

.icon-plus {
  display: inline-block;
  width: 15px;
}

.icon-bool {
  background-size: contain;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.icon-sortable {
  display: inline-block;
  width: 18px;
  vertical-align: 20%;
}

.icon-bool--false {
  background-image: url('/static/images/bool_false.png');
}

.icon-bool--true {
  background-image: url('/static/images/bool_true.png');
}

.icon-document {
  display: inline-block;
  width: 28px;
  height: 40px;
  vertical-align: -8px;
}


@media (max-width: $screen-md-min) {
  .icon-admin {
    width: 30px;
    margin-top: 1em;
  }
}
