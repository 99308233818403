.pagination {
  list-style: none;
  padding-left: 0px;
  li {
    display: block;
    float: left;
    margin-right: 1px;
    margin-bottom: 1px;
    padding: 8px 16px;
    background-color: $brand-color;
    color: $white;
    a {
      color: $white;
    }
    &:hover, &.active {
      background-color: lighten($brand-color, 10%);
    }
  }
}