.page--customers {
  
  .accordion {
    box-shadow: 1px 1px 3px transparentize($black, .8);
    border-color: lighten($alt-color-light, 25%);
  }
  
  .accordion__body {
    background-color: $alt-color-lighter;
    
    .grid__item {
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
    
  }
  
  .icon-eye {
    filter: brightness(0) saturate(100%) invert(69%) sepia(1%) saturate(0%) hue-rotate(153deg) brightness(91%) contrast(78%);
  }
  
  .view--event--btn {
    padding-top: $spacing-unit-tiny/2
  }
  
  .accordion__header {
    background-color: $alt-color-lighter;
    border: 0;
    color: $black;
    transition: .2s background-color, color ease;
    padding-right: 50px !important;
    
    .pull-right {
      margin-right: -36px;
    }
    
    .icon-admin-arrow--white {
      margin-top: 0;
      filter: brightness(0) saturate(100%) invert(69%) sepia(1%) saturate(0%) hue-rotate(153deg) brightness(91%) contrast(78%);
    }
    
  }
  
  .accordion__header.active {
    
    background-color: $brand-color !important;    
    color: $white;
    
    .icon-admin-arrow--white {
      filter: none;
    }
    
  }
  
  .collapse--slide.active {
    max-height: 400px;
    overflow: auto;
  }
  
  .accordion__body.active {
    background-color: darken($alt-color-lighter, 5%)
  }

  .iban_refund_form {
    max-width: 500px;
  }

  .iban_refund_data {
    border-style: solid;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 1em;
    margin-bottom: 2em;
    margin-left: 1em;
    margin-right: 1em;
  }

  .iban_refund_data p {
    margin-top: 0em;
    margin-bottom: 0em;
    text-transform: none;
  }

  .is_refunded_message {
    background-color: $brand-color;
    padding: 10px;
  }

  .is_filled_in_message {
    background-color: $brand-color;
    padding: 10px;
  }

  .iban_refund_links {
    background-color: #0099CB;
    color: #fff;
    padding: 10px;
    text-align: center;
    a {
      color: #fff;
    }
    a:hover {
      color: #000;
    }
  }
}
