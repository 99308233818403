.btn {
  text-transform: uppercase;
}
.btn--primary:hover,
.btn--primary:focus {
  color: $white;
}

.btn--danger {
  color: white;
  background-color: $error;
}

.btn--danger:hover {
  color: white;
  background-color: lighten($error, 10%);
}

.btn--default {
  &.active {
    color: white;
    background-color: $brand-color;
  }
  &:hover {
    color: $brand-color;
  }
}

.btn--tab {
  color: darken($tab-color, 30%);
  background-color: $tab-color;
  &.active {
    color: white;
    background-color: $brand-color;
  }
  &:hover:not(.active) {
    color: white;
    background-color: darken($tab-color, 10%);
  }
}

.btn--buy-ticket {
  span {
    vertical-align: 6px;
  }
}

.btn--alt {
  color: $white;
  background-color: $alt-color;
  &:hover {
    color: $white;
    background-color: $alt-color-light;
  }
}

.btn--alt-yellow {
  color: $black;
  background-color: $alt-brand-color-yellow;
  &:hover {
    color: $black;
    background-color: lighten($alt-brand-color-yellow, 10%);
  }
}

.btn--corail {
  color: $white;
  background-color: $corail-light;

  &:hover {
    color: $white;
    background-color: lighten($corail-light, 10%);
  }
}

@media (max-width: $screen-md-min) {
  .btn--buy-ticket {
    position: fixed;
    right: 0;
    top: 60vh;
    transform: translateX(100%) translateX(-60px);
    transition: transform 0.25s ease-out, background-color 0.25s ease-out;
  }

  .btn--buy-ticket:hover {
    transform: translateX(0)
  }
}
