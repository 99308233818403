/*----------------------------------------*\
  GRID-flex
  Modern grid-flex using flexbox
\*----------------------------------------*/

.#{$namespace}grid-flex,
%grid-flex {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$spacing-unit-default;
}

  .#{$namespace}grid-flex__item,
  %grid-flex__item {
    width: 100%;
    flex: 0 0 auto;
    padding-left: $spacing-unit-default;
  }


/**
 * Alignment
 */

.#{$namespace}grid-flex--center,
%grid-flex--center {
  justify-content: center;
}

.#{$namespace}grid-flex--right,
%grid-flex--right {
  justify-content: flex-end;
}

.#{$namespace}grid-flex--middle,
%grid-flex--middle {
  align-items: center;
}

.#{$namespace}grid-flex--bottom,
%grid-flex--bottom {
  align-items: flex-end;
}

.#{$namespace}grid-flex--even,
%grid-flex--even {
  > .grid-flex__item {
    display: flex;
    flex-direction: column;

    > * {
      flex: 1 0 auto;
    }
  }
}


/**
 * Order
 */

.#{$namespace}grid-flex--rev,
%grid-flex--rev {
  flex-direction: row-reverse;
}


/**
 * Gutters size
 */

@include foreach-alternative-spacing() {
  .#{$namespace}grid-flex--#{$spacing-name},
  %grid-flex--#{$spacing-name} {
    margin-left: -#{$spacing-value};

    > .#{$namespace}grid-flex__item {
      padding-left: $spacing-value;
    }
  }
}


/**
 * Add spacing between rows
 */

.#{$namespace}grid-flex--multiline,
%grid-flex--multiline {
  margin-top: -$spacing-unit-default;

  > .#{$namespace}grid-flex__item {
    padding-top: $spacing-unit-default;
  }

  @include foreach-alternative-spacing() {
    &.#{$namespace}grid-flex--#{$spacing-name} {
      margin-top: -#{$spacing-value};

      > .#{$namespace}grid-flex__item {
        padding-top: $spacing-value;
      }
    }
  }
}