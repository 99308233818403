.active-page-url {
  @apply tw-bg-[length:100%_5px] #{!important};
}

.nav-item {
  @apply tw-uppercase tw-text-black tw-whitespace-nowrap tw-text-base tw-h-full tw-flex tw-items-center tw-relative tw-isolate tw-no-underline tw-bg-[linear-gradient(rgb(0,153,203),rgb(0,153,203))] tw-bg-no-repeat tw-bg-[length:0%_5px] tw-transition-[background-size] tw-duration-[0.5s] tw-ease-[cubic-bezier(0.2,0.77,0.28,1)] tw-bg-[0_100%] hover:tw-bg-[length:100%_5px];
}

[data-nav-container] {
  a {
    @apply hover:tw-text-brand;
    &.active {
      @apply tw-text-brand #{!important};
    }
  }
}

nav .is-active {
  @apply tw-underline tw-decoration-brand;
}
