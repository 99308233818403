.masonry-grid {
  width: 100%;
}

.masonry-grid__item {
  display: block;
}

.masonry-grid__sizer,
.masonry-grid__item {
  width: 100%; // 1 column
  height: 250px;
}

.masonry-grid__sizer {
  position: absolute;
}

@media (min-width: $screen-xs-masonry) {
  .masonry-grid__sizer,
  .masonry-grid__item {
    width: 33.33%; // 3 columns
  }

  .masonry-grid__item--width {
    width: 66.66%;
  }
}

@media (min-width: $screen-sm-masonry) {
  .masonry-grid__sizer,
  .masonry-grid__item {
    width: 25%; // 4 columns
  }

  .masonry-grid__item--width {
    width: 50%;
  }
}

@media (min-width: $screen-md-masonry) {
  .masonry-grid__sizer,
  .masonry-grid__item {
    width: 20%; // 5 columns
  }

  .masonry-grid__item--width {
    width: 40%;
  }
}

@media (min-width: $screen-lg-masonry) {
  .masonry-grid__sizer,
  .masonry-grid__item {
    width: 16.66%; // 6 columns
  }

  .masonry-grid__item--width {
    width: 33.33%;
  }
}
