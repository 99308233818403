.sessions-and-tickets-menu {
  .sessions-and-tickets-menu-label {
      padding-left: 30px;
  }
  .btn {
      text-align: left
    }
}

#id_child-ticket-allow_transfer {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

#id_child-ticket-allow_transfer li {
  list-style-type: none;
}

#id_child-ticket-allow_transfer li:first-child label {
  margin-top: 0;
}
