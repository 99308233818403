.search {
  input,
  button {
    height: 43px;
  }

  .autocomplete__menu {
    border: 1px solid #333;
    border-top: none;
  }


  .search__field {
    position: relative;

    .errorlist {
      padding-top: 5px;
      position: absolute;
    }

    &:nth-of-type(3),
    &:nth-of-type(4) {
      display: inline-block;
      width: 50%;
    }

    &:nth-of-type(3) {
      float: left;
      padding-right: 12px;
    }

    &:nth-of-type(4) {
      padding-left: 12px;
    }
  }

  &.search__menu {
    .search__field {
      &:nth-of-type(3),
      &:nth-of-type(4) {
        margin-bottom: 10px;
      }
    }
  }

  &.search__results {
    .icon-magnifier {
      height: 22px;
    }

    .search__form {
      display: flex;
      align-items: end;
      flex-direction: column;
      flex-wrap: wrap;

      @media (max-width: $screen-sm-max) {

        & .toggle-modify {
          display: none;
        }

        & > .search__group {

          & .trigger-search {
            display: block;
          }
        }

        & > .search-fields-wrapper {
          width: 100%;
          transition: max-height 0.25s ease-out;
          max-height: 300px;
          height: auto;
        }
        &.closed {
          & > .search-fields-wrapper {
            max-height: 0;
            overflow: hidden;
          }

          & .toggle-modify {
            display: block;
          }

          & .trigger-search {
            display: none;
          }

        }
      }

      @media (min-width: $screen-mdl-min) {
        flex-direction: row;
        flex-wrap: nowrap;
      }

      & > .search-fields-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      & .search__group {
        position: relative;
        display: flex;
        align-items: end;
        flex-wrap: wrap;
        width: 100%;
        @media (min-width: $screen-mdl-min) {
          flex-wrap: nowrap;
          width: auto;
        }

        &:first-of-type {
          @media (min-width: $screen-mdl-min) {
            flex: 0 0 40%;
          }

          .search__field {
            width: 100%;

            @media (min-width: $screen-mdl-min) {

              &:first-of-type {
                padding-right: 12px;
              }

              &:nth-of-type(2) {
                padding-left: 12px;
              }
            }

          }
        }

        &:nth-of-type(2) {
          margin-left: auto;

          .search__field {
            width: 50%;
            margin-bottom: 20px;

            @media (min-width: $screen-mdl-min) {
              margin-bottom: 0;
              width: 200px;
            }

            &:first-of-type {
              padding-right: 12px;
            }

            &:nth-of-type(2) {
              padding-left: 12px;
            }
          }
        }
      }

      label {
        white-space: nowrap;
      }
    }
  }

  .search__separator {
    border-bottom: 1px solid $brand-color;
  }
}

.js-search-toggle {
  top: 80px;
}

.search__group__nav {
  .errorlist {
    position: absolute;
  }
}
