.ui-datepicker {
  display: none;
  background: repeating-linear-gradient(
                  -45deg,
                  $alt-color-lighter,
                  $alt-color-lighter 2px,
                  $white 2px,
                  $white 10px
  );
  span {
    cursor: default;
  }
  border: 1px solid $black;
  padding: $spacing-unit-small;
  .ui-datepicker-header {
    display: inline;
    .ui-datepicker-prev {
      float: left;
      &::before {
        content: '<<';
      }
    }
    .ui-datepicker-next {
      float: right;
      &::before {
        content: '>>';
      }
    }
    .ui-datepicker-prev, .ui-datepicker-next {
      background-color: $brand-color;
      padding: 4px 8px;
      font-size: 0;
      &::before {
        color: $white;
        font-size: $font-size-default;
      }
      &:hover {
        background-color: lighten($brand-color, 10%);
      }
    }
    .ui-datepicker-title {
      text-align: center;
      line-height: 2rem;
    }
  }

  table {
    margin-bottom: 0;
    thead {
      th {
        font-weight: normal;
      }
    }
    td {
      &:not(.ui-datepicker-unselectable) {
        background-color: $white;
        border: 1px solid $black;
        cursor: pointer;
        text-align: center;
        padding: 5px;
        &.ui-datepicker-current-day, &:active {
          background-color: $brand-color;
          a {
            color: $white;
          }
        }
        &:hover {
          background-color: lighten($brand-color, 10%);
          a {
            color: $white;
          }
        }
      }
      a {
        color: $black;
      }

      &.ui-datepicker-unselectable {
        background-color: $alt-color;
        border: 1px solid #000;
        text-align: center;
        padding: 5px;
      }

      &.ui-datepicker-other-month {
        background-color: $white;
      }
    }
  }
}
