.toggle-filters {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-transform: uppercase;

  .icon {
    width: 1em;
    height: 1em;
    transition: .3s transform ease;
  }
}

.toggle-filters[aria-expanded="true"] {
  .icon {
    transform: scaleY(-1);
  }
}
