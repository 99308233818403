/* oswald-300 - latin-ext_latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/oswald-v16-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local('Oswald Light'), local('Oswald-Light'),
       url('../fonts/oswald-v16-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/oswald-v16-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/oswald-v16-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/oswald-v16-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/oswald-v16-latin-ext_latin-300.svg#Oswald') format('svg'); /* Legacy iOS */
}

/* oswald-regular - latin-ext_latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/oswald-v16-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Oswald Regular'), local('Oswald-Regular'),
       url('../fonts/oswald-v16-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/oswald-v16-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/oswald-v16-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/oswald-v16-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/oswald-v16-latin-ext_latin-regular.svg#Oswald') format('svg'); /* Legacy iOS */
}

/* oswald-700 - latin-ext_latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/oswald-v16-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local('Oswald Bold'), local('Oswald-Bold'),
       url('../fonts/oswald-v16-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/oswald-v16-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/oswald-v16-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/oswald-v16-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/oswald-v16-latin-ext_latin-700.svg#Oswald') format('svg'); /* Legacy iOS */
}
