.nav-admin {
  width: 100%;
  background-color: $brand-color;
}

.nav-admin__link, .nav-admin__link:hover {
  color: $white;
}

.page-dashboard .nav-admin__link--dashboard,
.page-events .nav-admin__link--event,
.page-location .nav-admin__link--location,
.page-self_ticket .nav-admin__link--self_ticket,
.page-profile .nav-admin__link--profile,
.page-custom-sales-channel .nav-admin__link--custom-sales-channel,
.page-member-settlements .nav-admin__link--member-settlements,
.page-member-statistics .nav-admin__link--member-statistics,
.page-sales-reporting .nav-admin__link--sales-reporting {
  color: $alt-brand-color;
}

@media (min-width: $screen-md-min) {

  .nav-admin {
    position: fixed;
    top: 0;
    z-index: 3;
  }
}
