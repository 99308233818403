.modal {
  
  .modal--title {
    background-color: $brand-color;
    color: $white;
    margin: 0;
    padding: $spacing-unit-small $spacing-unit-default;
    position: relative;
  }

  @media screen and (min-width: $screen-md-min) and (min-height: $screen-sm-min) {
    
    .modal--window {
      max-width: 1000px;
    }
    
  }
  
}

.modal:not(.no-js) {
  display: none;
  position: fixed;
  background-color: transparentize($black, .8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  z-index: 60;

  &.open {
    display: block;
  }
  
  .modal--window {
    position: relative;
    background-color: $white;
    width: 100%;
    height: 100%;
  }

  .modal--content {
    padding: 0 $spacing-unit-default 0 $spacing-unit-default;
    position: absolute;
    top: $spacing-unit-small * 2 + $line-height-default * $font-size-default;
    bottom: 0;
    width: 100%;
    overflow-y: auto;

    // padding bottom workaround for overflow
    &:after {
      content: '';
      display: block;
      height: $spacing-unit-default;
    }
  }
  
  @media screen and (min-width: $screen-md-min) and (min-height: $screen-sm-min) {
    
    .modal--window {
      position: relative;
      top: 10vh;
      margin: auto;
      height: auto;
      width: 50%;
      margin-bottom: $spacing-unit-default;
      margin-top: - ($spacing-unit-small * 2 + $line-height-default * $font-size-default) / 2;
    }

    .modal--content {
      top: 0;
      position: relative;
      max-height: 80vh;
    }
    
  }
  
}

.modal.no-js {
  
  .close--modal {
    display: none;
  }
  
  .modal--window {
    background-color: $alt-color-lighter;
  }
  
  .modal--content {
    padding: 0 $spacing-unit-default;
  }
  
}