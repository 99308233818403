$fisrt-tile-color: $alt-brand-color-yellow;
$tile-border: 3px solid $black;

.square-grid {
  display: flex;
  flex-wrap: wrap;

  .square-grid__item {
    display: block;
    width: 50%;
  }

  .square-grid__item:nth-of-type(1) {
    background-color: $fisrt-tile-color;
  }

  .square-grid__item-content {
    border-bottom: $tile-border;
    border-right:$tile-border;
    display: block;
    height: 0;
    padding: 0 0 100% 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    color: $black;
    position: relative;
  }

  // the first element every 2 elements has a border on the left
  .square-grid__item:nth-of-type(2n+1) {
    .square-grid__item-content {
      // this one makes the padding smaller
      border-left: $tile-border;
    }
  }

  // The first 2 elements have a border on the top
  .square-grid__item:nth-of-type(-n+2) {
    .square-grid__item-content {
      border-top: $tile-border;
    }
  }

  .tile__info {
    position: absolute;
    padding: 10px;
    // shoud be 0 but set at -1px to account for render issues due to the browser rounding sizes from percentages
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    color: $white;
    background-color: $black;
    opacity: 0;
    transition: .3s opacity ease;
  }

  .square-grid__item-content:hover, .square-grid__item-content:focus {
    .tile__info {
      opacity: 1;
    }
  }

}

@media (min-width: $screen-md-min) {

  .square-grid {

    .square-grid__item {
      width: 25%;
    }

    // reset previous rule of every 2 elements
    .square-grid__item:nth-of-type(2n+1) {
      .square-grid__item-content {
        // this one makes the padding smaller
        border-left: none;
      }
    }

    // the first element every 4 elements has a border on the left
    .square-grid__item:nth-of-type(4n+1) {
      .square-grid__item-content {
        // this one makes the padding smaller
        border-left: $tile-border;
      }
    }

    // The first 4 elements have a border on the top
    .square-grid__item:nth-of-type(-n+4) {
      .square-grid__item-content {
        border-top: $tile-border;
      }
    }

  }

}
