.border {
  border: 1px solid $alt-color;
}

.brdrb {
  border-bottom: 1px solid $alt-color;
}

.brdrt {
  border-top: 1px solid $alt-color;
}

.brdrl {
  border-left: 1px solid $alt-color;
}

.brdrr {
  border-right: 1px solid $alt-color;
}

.brdrs {
  border-left: 1px solid $alt-color;
  border-right: 1px solid $alt-color;
}

.border-bottom {
  border-bottom: 1px solid $alt-color;
  opacity: 0.2;
}
