// Base
html {
  text-transform: uppercase;
}

// Links
a {
  color: $brand-color;
  text-decoration: none;
}

a:hover {
  color: $brand-color;
}

// Placeholder
@include placeholder {
  text-transform: uppercase;
}

// Long text
.text_block {
  font-weight: 300;
  text-transform: none;
  overflow-wrap: break-word;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}
