.payment-page-saferpay-iframe {
  frameborder: 0; // not HTML5
  border-width: 0px; // HTML5
  width: 100%;
  max-width: $screen-md-min;
  min-height: 700px;
}

@media (min-width: $screen-md-min) {
  .payment-page-saferpay-iframe {
    padding-right: 24px;
    max-width: 100%;
    min-height: 500px;
  }
}
