.accordion {
  .active {
    .rotate {
      @apply tw-rotate-180
    }
  }
}

.accordion__header {
  background-color: $alt-color-light;
  cursor: pointer;
  border-bottom: 1px solid $alt-color-dark;

  &.active .icon-admin-arrow {
    background-image: url('/static/images/arrow-up.svg');
  }

  &.active .icon-admin-arrow--white {
    background-image: url('/static/images/arrow-up--white.svg');
  }

  h1 {
    line-height: 40px;
  }

  &--disabled {
    cursor: default;

  }
}

.accordion__body {
  background-color: $alt-color-lighter;
  transition: all 0.2s ease-out;
}

.icon-admin-arrow {
  height: 15px;
  width: 19px;

  background: url('/static/images/arrow-down.svg') no-repeat;
}

.icon-admin-arrow--white {
  height: 15px;
  width: 19px;
  background: url('/static/images/arrow-down--white.svg') no-repeat;
}

// Style accordion sub-elements accordions to give some precedence
.artists, .child-tickets {
  width: 90%;
}

.accordion--campaign-section {
  .accordion__header.active {
    color: $white;
    background-color: $brand-color;
  }

  .accordion__body {
    background: $white;
  }
}


.accordion--event {
  .accordion__header {
    border-top: 2px solid $black;
    background-color: $white;
    border-bottom: none;

    .icon {
      transition: transform 200ms;
      transform: scaleY(1);
      height: 20px;
      width: 20px;
    }

    &.active {
      .icon {
        transform: scaleY(-1);
      }
    }
  }

  .accordion__body {
    background-color: $white;
  }
}

.accordion--campaign-section .accordion__header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.accordion__title {
  flex-grow: 1;
}

.question-answer {
  p:first-of-type {
    @apply tw-mt-0;
  }
  p {
     @apply tw-normal-case;
  }
}
