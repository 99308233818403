.tickets-selection-box {
  position: fixed;

  // 60px is the height of the mobile footer
  bottom: calc(60px + #{$spacing-unit-default});
  right: $spacing-unit-default;
  z-index: 10;

  transition: bottom 0.25s ease-out;
}

body.nav-footer-closed .tickets-selection-box {
  bottom: $spacing-unit-default;
}

@include media('md') {
  .tickets-selection-box {
    right: 48px;
    bottom: $spacing-unit-default;
  }
}

@include media('mdl') {
  .tickets-selection-box {
    position: sticky;
    padding: $spacing-unit-default;

    top: $spacing-unit-default;
    bottom: auto;
    right: auto;
    left: auto;

    background-color: $alt-color-lighter;
  }
}
