/**
 * BOX
 */

.box {
  p, h4 {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  .box__title {
    text-transform: uppercase;
  }

  .box__files {
    overflow: hidden;

    .link-document {
      display: inline-block;
      width: 75%;
      vertical-align: middle;
    }
  }
}

// Box Multicolor
.box--multicolor {
  height: 100%;
  text-transform: initial;
}

  .box--multicolor__footer {
    border-top-width: 1px;
    border-top-style: solid;

    // Format DOM el output thought WYSIWYG
    p {
      margin: 0;
    }

    a {
      font-weight: bold;
    }
  }

.box--red {
  color: $white;
  background-color: $corail;

  a {
    color: inherit;
  }

  .icon-document {
    stroke: currentColor;
  }

  .box--news__footer {
    border-top-color: inherit;
  }
}

.box--blue {
  color: $white;
  background-color: $brand-color;

  a {
    color: inherit;
  }


  .icon-document {
    stroke: currentColor;
  }

  .box--news__footer {
    border-top-color: inherit;
  }
}

.box--black {
  color: $alt-brand-color;
  background-color: $black;

  a {
    color: inherit;
  }

  .icon-document {
    stroke: currentColor;
  }

  .box--multicolor__footer {
    border-top-color: inherit;
  }
}
