.ticket-quantity-selector {
  @apply tw-w-8 tw-h-8 tw-relative tw-rounded-full disabled:tw-opacity-20 before:tw-content-[''] before:tw-top-1/2 before:tw-left-1/2 before:tw-w-4 before:tw-h-0.5 before:tw-absolute before:tw-rounded-full before:tw-translate-x-[-50%] before:tw-translate-y-[-50%]
}

.ticket-quantity-selector--minus {
  @apply tw-border-solid tw-border-1 tw-border-black tw-bg-transparent hover:tw-bg-transparent before:tw-bg-black
}

.ticket-quantity-selector--plus {
  @apply tw-bg-brand tw-text-white tw-border-none before:tw-bg-white after:tw-content-[''] after:tw-top-1/2 after:tw-left-1/2 after:tw-w-0.5 after:tw-h-4 after:tw-absolute after:tw-bg-white after:tw-rounded-full after:tw-translate-x-[-50%] after:tw-translate-y-[-47%]
}
