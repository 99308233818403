.multi-events-selector-container {

  &.expanded {
    .caret {
      @apply tw-rotate-180;
    }

    [data-role="dropdown"] {
      @apply tw-block;
    }

    .nested {
      @apply tw-hidden;
      &.active {
        @apply tw-block;
      }
    }
  }

}
